import IntegrationPage from 'components/integration/IntegrationPage';
import React from 'react';

export const IntegrationContainer = () => {
  return (
    <div>
      <IntegrationPage />
    </div>
  );
};
