import classNames from 'classnames';
import styles from './jobIndexStats.module.scss';
import dayjs from 'dayjs';

export const JobIndexStats = (props) => {
  const { views, applied, postedOn, expiresOn, job, matches, onClick } = props;
  const expiry = dayjs(expiresOn).startOf('day');
  const today = dayjs().startOf('day');
  const diffDays = expiry.diff(today, 'day'); // number of days until expiry

  return (
    <>
      <div
        className={classNames(styles.statsWrapper)}
        onClick={(e) => {
          onClick();
        }}
      >
        <div className={styles.jobGrayStat}>
          <strong>{views}</strong>
          <div className="jobBlueStatLabel">Views </div>
        </div>
        {applied && (
          <div className={styles.jobBlueStat}>
            {applied?.length}
            <div className="jobBlueStatLabel">Applied</div>
          </div>
        )}
        {matches && (
          <div className={styles.jobBlueStat}>
            {matches}
            <div className="jobBlueStatLabel">Matches </div>
          </div>
        )}
        {postedOn && (
          <div>Posted {dayjs(postedOn).format('D-MMM-YYYY')}</div>
        )}
        {(() => {
          if (diffDays < 0) return <div className={classNames(styles.redExpiry)}>Expired</div>;
          if (diffDays === 0) return <div className={classNames(styles.redExpiry)}>Expires Today</div>;
          if (diffDays === 1) return <div className={classNames(styles.redExpiry)}>Expires Tomorrow</div>;
          return (
            <div className={classNames(styles.redExpiry)}>
              Expires on {expiry.format('D-MMM-YYYY')}
            </div>
          );
        })()}
      </div>
    </>
  );
};
