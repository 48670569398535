import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import styles from './jobImageLogoDescription.module.scss';
import logoDefault from '../../static/images/hotel-logo-default.png';
import { LogoUploader } from '../form/logoUploader';

export const JobImageLogoDescription = (props) => {
  const { job, logo, hideUploadButton } = props;
  const { setValue } = useFormContext();
  const jobLogo = useWatch({ name: 'logo' });

  const [logoValue, setLogoValueState] = useState(logo || jobLogo || logoDefault);

  useEffect(() => {
    setLogoValueState(jobLogo);
  }, [jobLogo]);

  useEffect(() => {
    setLogoValueState(logo);
  }, [logo]);

  useEffect(() => {
    if (!logo && !jobLogo) {
      setLogoValueState(logoDefault);
    }
  }, []);

  // Bind logo value to the form if uploader is hidden.
  useEffect(() => {
    if (hideUploadButton) {
      setValue('logo', logoValue, { shouldValidate: true });
    }
  }, [hideUploadButton, logoValue, setValue]);

  return (
    <div className={classNames(styles.inputWrapper)}>
      <div className={classNames(styles.logoDisplayWrapper)}>
        <img
          src={logoValue}
          width="110"
          height="110"
          alt={job?.name}
          className={classNames(styles.jobLogoImage)}
        />
      </div>
      { !hideUploadButton && (
      <div className={classNames(styles.uploadButtonContainer)}>
        <div className={classNames(styles.uploadContentWrapper)}>
          <span className={classNames(styles.inputTitle)}>
            Add a company logo
            <div className={classNames(styles.textSmall, 'mb-0')}>
              {'For best results, upload square images.'}
            </div>
            <div className={classNames(styles.textSmall)}>
              {'Jpg, Png, or Gif file types only.'}
            </div>
          </span>
        </div>
        <div className={classNames(styles.uploadContentWrapper)}>
          <LogoUploader 
            submit 
            inline 
            size={'small'} 
            name="logo" 
            noImg={true} 
            isIcon={false} 
            logoDefault={logoValue}
          />
        </div>
      </div>
      )}
    </div>
  );
};
