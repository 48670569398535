import React from 'react';
import { MdAttachment as AttachmentIcon, MdEmail as EmailIcon, MdRestore as RestoreIcon } from 'react-icons/md';
import classNames from 'classnames';

import styles from './kanban.module.scss';
import { Avatar } from '../avatar/avatar';
import { Heading } from '../heading/heading';
import { useDispatch } from 'react-redux';
import { views } from '../../constants/views';
import { handleViewsData } from 'redux/common/commonSlice';
import { useDrag } from 'react-dnd';
import { restoreCandidate } from '../../utils/restoreCandidate';

export const KanbanCard = ({
  title,
  user,
  pipeline_stage_id,
  id,
  description,
  checkBoxAction,
  expertise_string,
  name,
  binned,
  projectData,
  updateProject,
}) => {
  const dispatch = useDispatch();

  
  return (
    <div
      className={styles.kanbanCard}
      data-testid={`card`}
    >
      <div className="d-flex justify-content-start gap-3">
        {checkBoxAction}
        <div
          className="d-flex align-items-center"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              handleViewsData({
                talent: { user: user },
                currentView: views.talentEmail,
              }),
            );
          }}
        >
          <Avatar
            src={user.avatar}
            name={user.full_name}
            className={classNames(styles.avatar, styles.img_avatar)}
            size={40}
          />
        </div>
        <div
          className="d-flex"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              handleViewsData({
                talent: { user: user },
                currentView: views.talentEmail,
              }),
            );
          }}
        >
          <div className={(styles.kanbanCardHead, 'd-flex justify-content-center flex-column')}>
            <Heading size="small" className={styles.kanbanCardHeading}>
              {user?.full_name}
            </Heading>

            <div className={styles.kanbanCardDescription}>
              {user?.current_experience ? user?.current_experience.title : user?.expertise_string}
            </div>
            <div className={styles.kanbanCardDescription}>
              {user?.current_experience ? user?.current_experience.company_name : ''}
            </div>
            <div className={styles.kanbanCardDescription}>
              {user?.current_experience ? user?.current_experience.area : ''}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end gap-2">
        <div>
          <AttachmentIcon className={styles.attachment} size={20} />
        </div>
        <div>
          <EmailIcon className={styles.inbox} size={20} />
        </div>
        {Boolean(binned) && (
          <button
            onClick={() => restoreCandidate(id, projectData, updateProject)}
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <RestoreIcon size={20} color="#28a745" />
          </button>
        )}
      </div>
    </div>
  );
};
