import React from 'react';
import classNames from 'classnames';

import styles from './JobIndicatorPreview.module.scss';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';

export const JobIndicatorPreview = (props) => {
  const { colors, color_id, acronym, onClick } = props;

  const { height, width, style = {}, className } = props;
  return (
    <div
      className={classNames(styles.jobIndicatorWrapper, styles[className])}
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : undefined,
        backgroundColor:
          '#' + ((colors && colors.find((color) => color.id === color_id)?.hex) || 'CCCCCC'),
        height: height || '50px',
        width: width || '50px',
        ...style,
      }}
    >
      <div className={classNames(styles.jobIndicatorContent)}>{acronym}</div>
    </div>
  );
};
