import React from 'react';
import { Avatar } from '../avatar/avatar';
import { JobIndicatorPreview } from '../jobIndicatorPreview/JobIndicatorPreview';

import stylesJob from '../jobIndexCard/JobForm.module.scss';
import classNames from 'classnames';

import styles from './JobLabel.module.scss';
import { Badge } from 'reactstrap';
import { appUrl } from '@constants/baseUrl';

export const JobLabel = (props) => {
  const {
    colors,
    client,
    clientLogo,
    job,
    size = 50,
    showClient = true,
    titleLink = false,
    className,
    classIndicator,
  } = props;
  return (
    <>
      <div className={classNames(styles.parentWrapper)}>
        <div className={classNames(styles.iconContainer)}>
          <Avatar
            name={job?.title}
            size={size}
            src={job?.logo}
            style={{
              zIndex: '2',
              display: 'inline-block',
              float: 'left',
              position: 'relative',
              cursor: 'pointer' // added for interactivity
            }}
            onClick={() => window.location.href = `${appUrl}/company/${job?.client?.slug}`} // navigate on logo click
            className={classNames(className)}
          />
          <div>
            <JobIndicatorPreview
              className={classIndicator}
              acronym={job?.indicator_acronym || job?.acronym}
              colors={colors}
              color_id={job?.colour_id}
              style={{ cursor: 'pointer' }} // added for interactivity
              onClick={() => window.location.href = `${appUrl}/jobs/${job?.id}`} // navigate on click
            />
          </div>
        </div>

        <div className={classNames(styles.titleContainer)}>
          <div
            className={classNames(
              stylesJob.inputTitle,
              { [styles.titleLink]: titleLink },
              'me-2 mb-0',
            )}
            onClick={titleLink}
            title={job?.name}
          >
            {job?.title}
            {job?.type === 'Premium' && (
              <Badge className={classNames('premiumPill ms-2 d-inline', stylesJob.jobPremiumPill)}>
                Premium
              </Badge>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
