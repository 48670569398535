import { isEmpty } from 'lodash';
// import { ExternalLinkIcon, RefreshIcon } from 'components/Icons';
// import Tooltip from "components/Tooltip";

import classNames from 'classnames';
import styles from '../jobForm/jobForm.module.scss';
import statusStyles from './jobPostStatus.module.scss';
import { Button } from '../button/button';

// import apiService from '../services/apiService';
// import apiUrls from '../apiUrls';
import { JobPublishedOn } from '../jobPublishedOn/jobPublishedOn';
import { DateSelector } from '../form/dateSelector';
import { JobApplyRateStates } from '../jobApplyRateStats/jobApplyRateStates';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

export const JobPostStatus = (props) => {
  const {
    showCreate,
    showConfirm,
    showPostConfirm,
    // handleSubmit,
    loading,
    // onSubmit,
    job,
    isSubmitting,
    isEditing,
    refreshJobData,
    form,
    jobData,
    submitForm,
  } = props;

  const { register } = useFormContext();
  const today = dayjs().format('YYYY-MM-DD');
  const threeMonthsFromNow = dayjs().add(3, 'month').format('YYYY-MM-DD');

  useEffect(() => {
    if (jobData) {
      form.reset({
        publish_date: jobData?.publish_date && dayjs(jobData?.publish_date).format('DD-MMM-YYYY'),
        expiry_date: jobData?.expiry_date && dayjs(jobData?.expiry_date).format('DD-MMM-YYYY'),
      });
    }
  }, [jobData]);

  return (
    <>
      {/* CREATE AND EDIT */}
      {showCreate && (
        <div className={classNames(styles.compCardContainer, styles.fixedCard)}>
          <div className={classNames(styles.compCardTitleContainer)}>
            <span className={classNames(styles.dividerTextLarge)}>Job post status</span>
          </div>
          <div className={classNames(styles.compCardContent, 'd-flex w-100 flex-column')}>
            <div className="row">
              <div className="col-6">
                <DateSelector
                  // disabled={isEditing}
                  {...register("publish_date")}
                  name="publish_date"
                  label="Published on:"
                  placeholder="Unpublished"
                  defaultValue={today}
                />
              </div>
              <div className="col-6">
                <DateSelector
                  // disabled={false}
                  {...register("expiry_date")}
                  name="expiry_date"
                  label="Expires on:"
                  placeholder="Expired On"
                  defaultValue={threeMonthsFromNow}
                />
              </div>
            </div>
            <JobApplyRateStates views={job && job.num_views} applied={null} />

            {/* TODO GET APPLIED COUNT */}
            {/* <JobPublishedOn headText={'Published on:'}/> */}
            {/* <JobPublishedOn headText={'Expired On:'} />
              <DateSelector name="end_date" /> */}

            {/* <JobApplyRateStats
              views={job && job.num_views}
              applied={null}
            />
            <JobPublishedOn
              text={
                null ||
                (job &&
                  job.published &&
                  moment(job.published).format("YYYY-MM-DD"))
              }
            />
            <JobExpiresOn input /> */}
            <div>
              <Button
                submit
                disabled={loading}
                type="submit"
              >
                {isEditing ? 'Update job post' : 'Publish job post'}
              </Button>
            </div>
            {isEditing ? (
              <div>
                {/* <DateSelector name="end_date" label="Expires on:" placeholder="Unpublished" />
                  <Button
                    style={{ backgroundColor: '#FD3732', marginTop: '20px' }}
                    // onClick={() => removeJob(jobId)}
                  >
                    {'Delete job post'}
                  </Button> */}
              </div>
            ) : (
              ''
            )}
            {/* <div className={ classNames(styles.textSmall) }>
              { 'This only saves your post details. In the next step you can preview and then publish your job.' }
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};
