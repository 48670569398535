import * as Yup from 'yup';

const loginValidationSchema = () => {
  const schema = Yup.object({
    email: Yup.string().email().required('Required'),
    password: Yup.string().required('Required'),
  });
  return schema;
};

const clientCreationSchema = () => {
  const schema = Yup.object({
    title: Yup.string().required('Required').max(50),
    client_type_id: Yup.number().required('Required').nullable(),
    description: Yup.string().required('Required').nullable(),
    logo: Yup.string().required('Required').nullable(),
    address_line_1: Yup.string().required('Required').nullable(),
    address_line_2: Yup.string().nullable(),
    address_line_3: Yup.string().nullable(),
    city: Yup.string().required('Required').nullable(),
    country_id: Yup.string().required('Required').nullable(),
    area_code: Yup.string().nullable(),
    state: Yup.string().nullable(),
    youtube_url: Yup.string().nullable(),
    vimeo_url: Yup.string().nullable(),
  });

  return schema;
};
const jobCreationSchema = () => {
  const schema = Yup.object({
    title: Yup.string().required('Required').nullable(),
    logo: Yup.string().required('Required').nullable(),
    description: Yup.string().required('Required').nullable(),
    client_id: Yup.number().required('Required').nullable(),
    membership_grade_id: Yup.number().nullable(),
    languages: Yup.array().min(0),
    qualifications: Yup.array().min(0),
    employment_type_id: Yup.number().nullable(),
    colour_id: Yup.string().required('Required').nullable(),
    acronym: Yup.string().required('Required').nullable(),
    position_levels: Yup.array().min(0),

    description: Yup.string().required('Required').nullable(),
    publish_date: Yup.string().required('Required').nullable(),
    expiry_date: Yup.string().required('Required').nullable(),
    employment_duration_id: Yup.string().when('employment_type_id', {
      is: (value) => value == 3,
      then: Yup.string().required('Please Select Contract Duration'),
      otherwise: Yup.string().notRequired(),
    }),
  });

  return schema;
};

const applicantsFilters = () => {
  const schema = Yup.object({
    Candidate_membership_grade: Yup.number().nullable(),
    ['Candidate_language(s)']: Yup.array().min(1).required('Required'),
    Candidate_qualification: Yup.array().test(
      'qualificationsCheckboxTestForFilters',
      'Select At Leat One Qualifications',
      (arr) => {
        let value = arr.find((v) => v !== false);
        if (!value) {
          return false;
        }
        return true;
      },
    ),
    Candidate_experience: Yup.array().test(
      'position_levelCheckboxTestForFilters',
      'Select At Leat One Position',
      (arr) => {
        let value = arr.find((v) => v !== false);
        if (!value) {
          return false;
        }
        return true;
      },
    ),
    Candidate_country_location: Yup.string().required('Required').nullable(),
  });

  return schema;
};
const applicantFormNotes = () => {
  const schema = Yup.object({
    note: Yup.string().required('Required'),
  });

  return schema;
};

const addProject = () => {
  const schema = Yup.object({
    pipeline_id: Yup.string().nullable(),
    company_role_id: Yup.string().nullable(),
    client_id: Yup.string().nullable(),
    country_id: Yup.string().nullable(),
    title: Yup.string().nullable(),
    city: Yup.string().nullable(),
    address: Yup.string().nullable(),
    latitude: Yup.string().nullable(),
    longitude: Yup.string().nullable(),
    teams: Yup.string().nullable(),
    colour_id: Yup.string().required('Required').nullable(),
  });
  return schema;
};
const addContact = () => {
  const schema = Yup.object({
    first_name: Yup.string().nullable(),
    last_name: Yup.string().nullable(),
    email: Yup.string().nullable(),
    professional_title: Yup.string().nullable(),
    department_id: Yup.string().nullable(),
    phone_number: Yup.string().nullable(),
    skype: Yup.string().nullable(),
    notes: Yup.string().nullable(),
  });
  return schema;
};

const createPipeline = () => {
  const schema = Yup.object({
    title: Yup.string().required('Required').nullable(),
    pipeline_stages: Yup.array().of(Yup.object({ title: Yup.string().required('Required') })),
  });
  return schema;
};

const searchCandidates = () => {
  const schema = Yup.object({
    keyword: Yup.string().nullable(),
    location: Yup.string().nullable(),
  });
  return schema;
};

const candidatesSearchFilters = () => {
  // const aliasis = {
  //   current_department: {
  //     header: 'Current department',
  //   },
  // };

  const schema = Yup.object({
    job_titles: Yup.string().nullable(),
    studied_at_institution: Yup.string().nullable(),
    current_position: Yup.string().nullable(),
    current_department: Yup.string().nullable(),
    gender: Yup.string().nullable(),
    company: Yup.string().nullable(),
    rank_experience: Yup.array().test(
      'position_levelCheckboxTest',
      'Select At Leat One Position',
      (arr) => {
        let value = arr.find((v) => v !== false);
        if (!value) {
          return true;
        }
        return true;
      },
    ),
    user_tags: Yup.array()
      .of(Yup.object({ title: Yup.string(), id: Yup.string() }))
      .test('position_levelCheckboxTest', 'Select At Leat One Position', (arr) => {
        let value = arr.find((v) => v !== false);
        if (!value) {
          return true;
        }
        return true;
      }),
    membership_grades: Yup.number().required('Required').nullable(),
    fluently_speaks: Yup.array().nullable(),
    marital_status: Yup.string().nullable(),
    qualification_achieved: Yup.array().test(
      'qualificationsCheckboxTest',
      'Select At Leat One Qualifications',
      (arr) => {
        let value = arr.find((v) => v !== false);
        if (!value) {
          return false;
        }
        return true;
      },
    ),
    countries: Yup.array()
      .of(Yup.object({ title: Yup.string(), id: Yup.string() }))
      .test('position_levelCheckboxTest', 'Select At Leat One Position', (arr) => {
        let value = arr.find((v) => v !== false);
        if (!value) {
          return true;
        }
        return true;
      }),
    employment_type_id: Yup.number().required('Required').nullable(),
    position_levels: Yup.array()
      .test('position_levelCheckboxTest', 'Select At Leat One Position', (arr) => {
        let value = arr.find((v) => v !== false);
        if (!value) {
          return true;
        }
        return true;
      })
      .nullable(),
  });

  return schema;
};

const saveSearchSchema = () => {
  const schema = Yup.object({
    company_role_id: Yup.number().required('Required').nullable(),
    title: Yup.string().required('Required').nullable(),
  });
  return schema;
};

const companyIntegrationSchema = () => {
  const schema = Yup.object({
    ghost_api_url: Yup.string(),
    ghost_admin_api_key: Yup.string(),
    ghost_content_api_key: Yup.string(),
  });
  return schema;
};

export default {
  loginValidationSchema,
  clientCreationSchema,
  jobCreationSchema,
  applicantsFilters,
  applicantFormNotes,
  addProject,
  addContact,
  createPipeline,
  searchCandidates,
  candidatesSearchFilters,
  saveSearchSchema,
  companyIntegrationSchema,
};
