import React from 'react';
import { useState, useEffect } from 'react';
import { BiFile as FileIcon } from 'react-icons/bi';
import { FiUser as UserIcon } from 'react-icons/fi';
import { MdCreditCard as CardIcon } from 'react-icons/md';
import { BsGear as GearIcon } from 'react-icons/bs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const PipelineNav = () => {
  const navigate = useNavigate();
  const [activeTabIndex, setActiveIndex] = useState(1);
  const location = useLocation();
  useEffect(() => {
    // if(activeTabIndex==1){
    //   navigate('/settings/pipelines')
    // }else{
    //   navigate('/settings')
    // };
    if (location.pathname == '/settings/roles') {
      setActiveIndex(2);
    } else if (location.pathname == '/settings/pipelines') {
      setActiveIndex(1);
    }
  }, []);

  return (
    <>
      <ul>
        <li
          onClick={() => {
            navigate('/settings/pipelines');
            setActiveIndex(1);
          }}
          className={activeTabIndex == 1 ? 'active' : ''}
        >
          <FileIcon size={18} />
          Pipeline templates
        </li>
        <li
          onClick={() => {
            navigate('/settings/roles');
            setActiveIndex(2);
          }}
          className={activeTabIndex == 2 ? 'active' : ''}
        >
          <UserIcon size={18} />
          Roles and permissions
        </li>
        <li
        // onClick={() => setActiveIndex(3)}
        // className={activeTabIndex == 3 ? 'active' : ''}
        >
          <CardIcon size={18} />
          Billing and account details
        </li>
        <li
          onClick={() => {
            navigate('/settings/jobs-embed');
            setActiveIndex(4);
          }}
          className={activeTabIndex == 4 ? 'active' : ''}
        >
          <CardIcon size={18} />
          Jobs Embed
        </li>
        <li
          onClick={() => {
            navigate('/settings/integrations');
            setActiveIndex(5);
          }}
          className={activeTabIndex == 5 ? 'active' : ''}
        >
          <GearIcon size={18} />
          Integrations
        </li>
      </ul>
    </>
  );
};
