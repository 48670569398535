/**
 * Restores a candidate from the bin by setting its binned flag to 0.
 *
 * @param {number} candidateId - The id of the candidate to restore.
 * @param {Object} projectData - The current project data.
 * @param {Function} updateProject - Mutation function to update the project.
 */
export function restoreCandidate(candidateId, projectData, updateProject) {
  // Guard clause for projectData
  if (!projectData || !Array.isArray(projectData.projects_pipeline_stages_users)) {
    console.error("Invalid projectData:", projectData);
    return;
  }

  let updatedCandidates = projectData.projects_pipeline_stages_users.map(candidate => {
    if (candidate.id === candidateId) {
      return { ...candidate, binned: 0 };
    }
    return candidate;
  });

  // only include id, project_id, user_id, pipeline_stage_id, and binned
  // in updatedCandidates

  updatedCandidates = updatedCandidates.map(({ id, project_id, user_id, pipeline_stage_id, binned }) => ({
    id,
    project_id,
    user_id,
    pipeline_stage_id,
    binned
  }));
  
  const updatedProject = {
    ...projectData,
    projects_pipeline_stages_users: updatedCandidates,
  };

  updateProject(updatedProject);
}
