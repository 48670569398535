import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { Form } from 'reactstrap';
import { Button } from '../../components/button/button';
import { FormProvider, useForm } from 'react-hook-form';
import { FiUser as UserIcon } from 'react-icons/fi';
import { AiFillLock as LockIcon } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../redux/apiService/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import yupSchemaService from '../../services/yupSchemaService';
import { TextInput } from '../../components/form/textInput';
import toast from 'react-hot-toast';
import { routesPath } from '../../constants/routesPath';
import storageService from '../../services/storageService';
import { AdvancedLink } from '../../components/advancedLink/advancedLink';
import { siteLinks } from '../../constants/siteLinks';
import styles from '../loginContainer/loginContainer.module.scss';

export const LoginContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const locale = queryParams.get('locale');

  const texts = locale === 'de' ? {
    title: 'Lass uns loslegen... Anmelden',
    email: 'E-Mail',
    password: 'Passwort',
    loginButton: 'Anmelden',
    success: 'Erfolgreich angemeldet!',
    error: 'E-Mail oder Passwort falsch!',
    linkText: 'Geschäftsbedingungen'
  } : {
    title: 'Let’s get hiring... Log in',
    email: 'Email',
    password: 'Password',
    loginButton: 'Log in',
    success: 'Successfully Logged In!',
    error: 'Email or Password Incorrect!',
    linkText: 'Terms and Conditions'
  };

  const onSubmit = (data) => {
    login(data);
  };
  const form = useForm({
    resolver: yupResolver(yupSchemaService.loginValidationSchema()),
    mode: 'all',
    defaultValues: {},
  });
  const [login, loginData] = useLoginMutation({
    fixedCacheKey: 'authData-sharing',
  });

  useEffect(() => {
    if (loginData.isSuccess) {
      storageService.setToken(
        loginData.data.data.token,
        loginData.data.data.user.email,
        loginData.data.data.user.id,
      );

      storageService.setUserInfo(loginData.data.data.user);
      toast.success(texts.success);
      navigate(routesPath.dashboard, { replace: false });
    } else if (loginData.isError) {
      toast.error(texts.error);
    }
  }, [loginData, navigate, texts]);

  return (
    <Container>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)} className={styles.loginForm}>
          <div className={styles.loginFormInner}>
            <h2>{texts.title}</h2>
            <div className="mb-4">
              <TextInput
                icon={<UserIcon />}
                placeholder={texts.email}
                required={true}
                name="email"
                type="text"
              />
            </div>
            <div className="mb-4">
              <TextInput
                icon={<LockIcon />}
                placeholder={texts.password}
                required={true}
                name="password"
                type="password"
              />
            </div>

            <div className="col-12">
              <Button
                className="w-100"
                color="primary"
                type="submit"
                disabled={loginData.isLoading}
                submit
              >
                {texts.loginButton}
              </Button>
            </div>
            <center>
              <AdvancedLink
                href={siteLinks.termsAndConditions}
                target="_blank"
                className={styles.terms}
              >
                {texts.linkText}
              </AdvancedLink>
            </center>
          </div>
        </Form>
      </FormProvider>
    </Container>
  );
};
