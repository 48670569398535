import { React, useEffect, useState } from 'react';
import { map, reduce } from 'lodash-es';
import { MenuList } from 'react-menu-list';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { GenericInput } from 'components/genericInput/genericInput';
import { Labeled } from 'components/labeled/labeled';
import { MenuAction } from 'components/menuAction/menuAction';
import { MenuDivider } from 'components/menuDivider/menuDivider';
import { MenuItem } from 'components/menuItem/menuItem';
import { MenuItemCard } from 'components/menuItemCard/menuItemCard';
import { MenuScrollable } from 'components/menuScrollable/menuScrollable';
import { MenuSlot } from 'components/menuSlot/menuSlot';
import { NavBar } from 'components/nav/navBar';
import { NavItem } from 'components/nav/navItem';
import { NavSegment } from 'components/nav/navSegment';
import { SearchButton } from 'components/searchButton/searchButton';
import { Section } from 'components/section/section';
import { routesPath } from 'constants/routesPath';
import clients from '../../redux/modules/clients';
import { Avatar, avatarOptions } from 'components/avatar/avatar';
import { MdOutlineSearch as SearchIcon } from 'react-icons/md';
import { AiOutlineCheck as CheckIcon } from 'react-icons/ai';
import { BiCalendarEvent as CalendarIcon } from 'react-icons/bi';
import { IoMdMailOpen as MailIcon } from 'react-icons/io';
import { Button } from 'components/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { baseSliceWithTags } from '../../redux/baseSlice';
import storageService from '../../services/storageService';
import { NavToggle } from 'components/nav/navToggle';

import styles from './navContainer.module.scss';
import classNames from 'classnames';
import { handleNavToggle } from '../../redux/common/commonSlice';
import { apiUrls } from '../../constants/apiurls';
import { NavbarMobile } from '@components/nav/navMobile';
import { appUrl } from 'constants/baseUrl';
import projects from '../../redux/modules/projects';

export const NavContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [clientSearchQuery, setClientSearchQuery] = useState(null);
  const [projectSearchQuery, setProjectSearchQuery] = useState(null);

  const {
    data: clientData,
    isLoading: isLoadingClient,
    error: errorClient,
    isSuccess: successClient,
  } = clients
    .list()
    .useClientslistQuery(
      { params: '', extendedPath: apiUrls.getClientListing() },
      { refetchOnMountOrArgChange: true },
    );
  
  const userInfo = JSON.parse(storageService.getUserInfo());

  const {
    data: projectData,
    isLoading: isLoadingProject,
    error: errorProject,
    isSuccess: successProject,
  } = projects.list().useProjectlistQuery({ extendedPath: '/index' });

  const { data: searchedProjects } = projects.list().useProjectlistQuery(
    { params: '', extendedPath: '/index' },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data }) => ({
        data: data?.data?.filter((el) => el.title.toUpperCase().includes(projectSearchQuery)),
      }),
    },
  );

  const { data: searchedClients } = clients.list().useClientslistQuery(
    { params: '', extendedPath: apiUrls.getClientListing() },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data }) => ({
        data: data?.data?.filter((el) => el.title.toUpperCase().includes(clientSearchQuery)),
      }),
    },
  );

  const authHandler = () => {
    dispatch(baseSliceWithTags.util.resetApiState());
    storageService.clearStorage();
    navigate(routesPath.login, { replace: false });
  };

  const filterList = (clients, query) => {
    setClientSearchQuery(query.toUpperCase());
  };

  const filterProjectList = (projects, query) => {
    setProjectSearchQuery(query.toUpperCase());
  };

  const isNavMobile = useSelector((state) => state.commonReducer.navBarToggle);
  return (
    <>
      <div
        className={classNames(styles.navigation, styles.mainNav)}
        style={{ position: 'fixed', width: '100%', zIndex: '99' }}
      >
        <NavBar type="mainmenu">
          <NavSegment placement="left">
            <NavToggle />
            <NavItem
              link={routesPath.dashboard}
              onClick={() => {
                dispatch(handleNavToggle(false));
              }}
            >
              Dashboard
            </NavItem>
            <NavItem
              zIndex={23}
              title={<Labeled toCount={clientData?.data}>Clients</Labeled>}
              hasDropdown
              active={location.pathname.includes(routesPath.clientFormPage)}
              link=""
            >
              <MenuSlot onSelected={(e) => e.stopPropagation()}>
                <GenericInput
                  type="text"
                  placeholder={'Search'}
                  iconRight
                  value={clientSearchQuery}
                  onChange={(event) => filterList('clients', event.target.value)}
                />
              </MenuSlot>
              <MenuScrollable>
                <MenuList>
                  {clientSearchQuery
                    ? map(searchedClients, (client) => (
                        <MenuItemCard
                          key={`c-${client.id}`}
                          link={routesPath.editClient(client.id)}
                          onClick={() => {
                            dispatch(handleNavToggle());
                            navigate(routesPath.getClientProjects(client.id), {
                              replace: false,
                            });
                          }}
                          name={client.title}
                          avatar={avatarOptions(client, 'client')}
                        />
                      ))
                    : clientData?.data?.length &&
                      !clientSearchQuery &&
                      map(clientData.data, (client) => (
                        <MenuItemCard
                          key={`c-${client.id}`}
                          link={routesPath.editClient(client.id)}
                          onClick={() => {
                            dispatch(handleNavToggle());
                            navigate(routesPath.getClientProjects(client.id), { replace: false });
                          }}
                          name={client.title}
                          avatar={avatarOptions(client, 'client')}
                        />
                      ))}
                </MenuList>
              </MenuScrollable>
              <MenuDivider />
              <MenuList>
                <MenuAction
                  onClick={() => {
                    navigate(routesPath.clientFormPage, { replace: false });
                    dispatch(handleNavToggle(false));
                  }}
                >
                  Add new client
                </MenuAction>
              </MenuList>
            </NavItem>
            {/* <NavItem
            highlight={highlightRoute.projects}
            zIndex={23}
            title={
              <Labeled
                toCount={reduce(
                  visibleProjects,
                  (accList, project) => [...accList, ...project.items],
                  [],
                )}
              >
                {' '}
                Projects
              </Labeled>
            }
            hasDropdown
          >
            <MenuSlot onSelected={(e) => e.stopPropagation()}>
              <GenericInput
                key="projects-filter"
                type="text"
                placeholder={'Search'}
                // icon={<SearchIcon size={20} />}
                iconRight
                // value={projectsFilter}
                // onChange={(event) => filterList('projects', event.target.value)}
              />
            </MenuSlot>
            <MenuScrollable>
              {map(visibleProjects, (project) => {
                if (project.client && project.client.id) {
                  return (
                    <Section
                      title={project.client.title}
                      key={`project-client-${project.client.id}`}
                      headingSize="large"
                    >
                      {map(project.items, (project) => (
                        <MenuItemCard
                          key={`cp-${project.id}`}
                          // link={routes.projectPipeline(project.client.id, project.id)}
                          name={project.title}
                          // avatar={{ ...avatarOptions(project, 'project') }}
                        />
                      ))}
                    </Section>
                  );
                }
              })}
            </MenuScrollable>
            <MenuDivider />
            <MenuAction
            // onClick={() => openOverlay(overlayViews.projectForm)}
            >
              Add new project
            </MenuAction>
          </NavItem> */}
            {/* {map(visibleProjects, (project) => {
            if (project?.client && project?.client?.id) {
              return (
                <Section
                  title={project.client.title}
                  key={`project-client-${project.client.id}`}
                  headingSize="large"
                >
                  {map(project.items, (project) => (
                    <MenuItemCard
                      key={`cp-${project.id}`}
                      // link={routes.projectPipeline(project.client.id, project.id)}
                      name={project.title}
                      // avatar={{ ...avatarOptions(project, 'project') }}
                    />
                  ))}
                </Section>
              );
            }
          })} */}
            {/* <NavItem
            highlight={highlightRoute.recruiters}
            zIndex={23}
            title={
              <Labeled
                toCount={reduce(visibleUsers, (accList, user) => [...accList, ...user.items], [])}
              >
                {' '}
                Recruiters
              </Labeled>
            }
            hasDropdown
          >
            <MenuSlot onSelected={(e) => e.stopPropagation()}>
              <Input
                key="recruiters-filter"
                type="text"
                placeholder={'Search'}
                icon={<SearchIcon size={20} />}
                iconRight
                value={usersFilter}
                onChange={(event) => filterList('users', event.target.value)}
              />
            </MenuSlot>
            <MenuScrollable>
              {map(visibleUsers, (users) => (
                <If condition={users.seniorityLevel && users.seniorityLevel.id}>
                  <Section
                    title={users.seniorityLevel.name}
                    key={`user-level-${users.seniorityLevel.id}`}
                    headingSize="large"
                  >
                    {map(users.items, (recruiter) => (
                      <MenuItemCard
                        key={`cp-${recruiter.id}`}
                        onClick={() =>
                          openSidebar(sidebarViews.recruiterProfile, {
                            sid: recruiter.id,
                          })
                        }
                        name={`${recruiter.first_name} ${recruiter.last_name}`}
                        avatar={{ ...avatarOptions(recruiter, 'recruiter') }}
                      />
                    ))}
                  </Section>
                </If>
              ))}
            </MenuScrollable>
            <MenuDivider />
            <MenuAction onClick={() => push(routes.addRecruiter)}>Add new recruiter</MenuAction>
          </NavItem> */}
            <NavItem
              link={routesPath.jobs}
              onClick={() => {
                dispatch(handleNavToggle(false));
              }}
            >
              Job posts
            </NavItem>
            <NavItem
              zIndex={23}
              title={<Labeled toCount={projectData?.data}>Projects</Labeled>}
              hasDropdown
              active={location.pathname.includes(routesPath.clientFormPage)}
              link=""
            >
              <MenuSlot onSelected={(e) => e.stopPropagation()}>
                <GenericInput
                  type="text"
                  placeholder={'Search'}
                  iconRight
                  value={projectSearchQuery}
                  onChange={(event) => filterProjectList('projects', event.target.value)}
                />
              </MenuSlot>
              <MenuScrollable>
                <MenuList>
                  {projectSearchQuery
                    ? map(searchedProjects, (project) => (
                        <MenuItemCard
                          key={`c-${project.id}`}
                          // link={routesPath.editClient(client.id)}
                          // onClick={() => {
                          //   dispatch(handleNavToggle());
                          //   navigate(routesPath.getClientProjects(client.id), {
                          //     replace: false,
                          //   });
                          // }}
                          name={project.title}
                          avatar={avatarOptions(project, 'client')}
                        />
                      ))
                    : projectData?.data?.length &&
                      !projectSearchQuery &&
                      map(projectData.data, (project) => (
                        <MenuItemCard
                          key={`c-${project.id}`}
                          link={routesPath.editClient(project.id)}
                          onClick={() => {
                            dispatch(handleNavToggle());
                            navigate(routesPath.getClientProjects(project.id), { replace: false });
                          }}
                          name={project.title}
                          avatar={avatarOptions(project, 'project')}
                        />
                      ))}
                </MenuList>
              </MenuScrollable>
            </NavItem>
            <NavItem
              onClick={() => {
                dispatch(handleNavToggle(false));
              }}
              link={routesPath.recruitersList()}
            >
              Recruiters
            </NavItem>
            <NavItem
              onClick={() => {
                dispatch(handleNavToggle(false));
              }}
              link={routesPath.pipelineList()}
            >
              Settings
            </NavItem>
          </NavSegment>
          <NavSegment placement="user">
            <NavItem>
              <GenericInput
                name="keyword"
                type="text"
                placeholder="Search Account"
                icon={<SearchIcon />}
                iconRight
                navSearch
                // value={searchQuery}
                // onChange={(event) => onSearch(event.target.value)}
              />
            </NavItem>
            <NavItem>
              <Button style={{ paddingInline: 10 + 'px' }}>
                <CheckIcon size={20} />
              </Button>
            </NavItem>
            <NavItem>
              <Button style={{ paddingInline: 10 + 'px' }}>
                <CalendarIcon size={20} />
              </Button>
            </NavItem>
            <NavItem>
              <Button style={{ paddingInline: 10 + 'px' }} size={'small'}>
                <MailIcon size={20} />
              </Button>
            </NavItem>
            <NavItem
              zIndex={23}
              className={classNames(styles.navAvatar, { [styles.mobileNavActive]: isNavMobile })}
              hasDropdown
              toRight
              title={
                <Avatar src={JSON.parse(localStorage.getItem('userData'))?.avatar} size={30} />
              }
            >
              {/* <MenuItem link="/profile">My Profile</MenuItem> */}
              {/* <MenuItem link="/change-password">Change Password</MenuItem> */}
              <MenuItem externalLink={`${appUrl}/profile/${userInfo.slug}`}>Go to Profile</MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch(handleNavToggle(false));
                  authHandler();
                }}
              >
                Logout
              </MenuItem>
            </NavItem>
            <NavItem link={routesPath.searchCandidates()}>
              <SearchButton />
            </NavItem>
          </NavSegment>
        </NavBar>
      </div>
      <NavbarMobile />
    </>
  );
};
