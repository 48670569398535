import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from 'constants/baseUrl';
import storageService from 'services/storageService';
import { TextInput } from 'components/form/textInput';
import { Form } from '../form/form';
import { FormProvider, useForm } from 'react-hook-form';
import yupSchemaService from 'services/yupSchemaService';
import { yupResolver } from '@hookform/resolvers/yup';

const IntegrationPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    ghost_api_url: '',
    ghost_admin_api_key: '',
    ghost_content_api_key: '',
  });

  const form = useForm({
    resolver: yupResolver(yupSchemaService.companyIntegrationSchema()),
    mode: 'all',
    defaultValues: {
      ...formValues,
    },
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/rest/organization/info`, {
        headers: {
          common: {
            AuthApi: `Bearer ${storageService.getToken()}`,
          },
        },
      });
      let company = response.data.data.company;

      form.setValue('ghost_api_url', company.ghost_api_url);
      form.setValue('ghost_admin_api_key', company.ghost_admin_api_key);
      form.setValue('ghost_content_api_key', company.ghost_content_api_key);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/rest/organization/update`, values, {
        headers: {
          common: {
            AuthApi: `Bearer ${storageService.getToken()}`,
          },
        },
      });
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="integration-container">
      <div className="card p-3">
        <h2 className="card-title">Integration</h2>
        <div className="card-section">
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
              <TextInput
                name="ghost_api_url"
                label="Ghost API URL"
                value={formValues.ghost_api_url}
              />
              <TextInput
                name="ghost_admin_api_key"
                label="Ghost Admin API Key"
                value={formValues.ghost_admin_api_key}
              />
              <TextInput
                name="ghost_content_api_key"
                label="Ghost Content API Key"
                value={formValues.ghost_content_api_key}
              />
              <div className="form-group mt-sm-3 mt-2">
                <button
                  className="btn btn-primary px-4 float-end"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? 'Saving...' : 'Save'}
                </button>
              </div>
            </Form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default IntegrationPage;
