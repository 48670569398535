import '@asseinfo/react-kanban/dist/styles.css';
import classNames from 'classnames';
import Board, { addCard, addColumn } from '@asseinfo/react-kanban';

import { KanbanCard } from '@components/kanbanCard/kanbanCard';
import { KanbanColumn } from '@components/kanbanColumn/kanbanColumn';
import styles from 'components/kanbanCard/kanban.module.scss';
import { apiUrls } from 'constants/apiurls';
import { useParams } from 'react-router-dom';
import dataService from '@services/dataService';
import { useCallback, useEffect, useState } from 'react';
import { Page } from '@components/page/page';
import projects from '../../redux/modules/projects';
import { ActionsBar } from '../../components/actionsBar/actionsBar';
import { KanbanActionsBar } from '../../components/kanbanActionBar/kanbanActionBar';
import { cloneDeep, find, flatten } from 'lodash';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { handleViewsData } from '../../redux/common/commonSlice';
import { Spinner } from 'components/loader/loader';
import { EmptyCard } from 'components/emptyCard/emptyCard';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
export const ProjectBinPipelineContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [columns, setColumns] = useState();
  const [board, setBoard] = useState();
  const [selectedCard, setSelectedcard] = useState([]);
  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  const {
    data: projectData,
    isLoading: isLoadingProject,
    error: errorProject,
    isSuccess: successProject,
  } = projects.list().useProjectlistQuery(
    { params: { id: params.projectId }, extendedPath: apiUrls.getProject() },
    {
      skip: !params.projectId,
    },
  );

  const [updateProject, updateProjectData] = projects.form().useProjectupdateMutation();

  useEffect(() => {
    if (successProject || selectedCard?.length) {
      let result = dataService.parsedKanbanColumnsBin(
        projectData?.data,
        selectedCard,
        getActionBar,
        getCardCheckBox,
      );

      setBoard(result);
    } else {
      setBoard(null);
    }
  }, [projectData, selectedCard]);

  useEffect(() => {
    if (updateProjectData?.isSuccess) {
      toast.success('Project Pipeline Updated!');
    }
    if (updateProjectData?.error) {
      toast.error('Failed To Update');
    }
  }, [updateProjectData]);

  useEffect(() => {
    if (!projectData?.data?.pipeline_id) {
      setBoard(null);
    }
    return () => {
      dispatch(handleViewsData(null));
    };
  }, []);

  const onCardDragEnd = (card, from, to, type, stages) => {
    let tempSelectedCards = [...selectedCard];
    let cards = card;
    let projectCandidates = cloneDeep(projectData?.data?.projects_pipeline_stages_users);
    if (type === 'restore') {
      // This handles binning logic when dragging towards the bin
      // Filter out the selected cards from the pipeline stage (from column)
      let cardsToRestore = tempSelectedCards.filter((cd) => cd.pipeline_stage_id === from.fromColumnId);
  
      // Remove those selected cards from the `selectedCard` state
      tempSelectedCards = tempSelectedCards.filter(
        (cd) => cd.pipeline_stage_id !== from.fromColumnId,
      );
  
      // Mark the filtered cards as binned
      projectCandidates.forEach((candidate) => {
        if (find(cardsToRestore, { id: candidate.id })) {
          candidate.binned = 0; // Mark candidate as binned
        }
      });

      projectCandidates = projectCandidates.map(({ id, project_id, user_id, pipeline_stage_id, binned }) => ({
        id,
        project_id,
        user_id,
        pipeline_stage_id,
        binned
      }));
  
      // Prepare updated project data to be sent to the backend
      let updatedProject = {
        id: parseInt(params?.projectId),
        projects_pipeline_stages_users: projectCandidates,
      };
  

      // Update the project on the server
      updateProject(updatedProject);

      return; // Stop here after handling bin logic
    }
    if (type === 'kanBnaBar') {
      cards = tempSelectedCards.filter((cd) => cd.pipeline_stage_id === from.fromColumnId);
      tempSelectedCards = tempSelectedCards.filter(
        (cd) => cd.pipeline_stage_id !== from.fromColumnId,
      );
      cards.map((cd) => {
        // delete cd.user;
        // delete cd.checkBoxAction;
        cd.pipeline_stage_id = to.toColumnId;
        return cd;
      });
    } else {
      cards['pipeline_stage_id'] = to.toColumnId;
    }
    let tempBoard = cloneDeep(board);
    let sourceColumnIndex = tempBoard.columns.findIndex((col) => col.id === from.fromColumnId);
    let targetColumnIndex = tempBoard.columns.findIndex((col) => col.id === to.toColumnId);

    let sourceColumn = tempBoard.columns
      .find((col) => col.id === from.fromColumnId)
      .cards.filter((card, index) => index !== from.fromPosition);
    let targetColumn = tempBoard.columns.find((col) => col.id === to.toColumnId);
    if (from.fromColumnId === to.toColumnId) {
      sourceColumn.splice(to.toPosition, 0, cards);

      tempBoard.columns[sourceColumnIndex].cards = sourceColumn;
      tempBoard.columns.map((col) => (col['candidateCount'] = col.cards?.length));
    } else {
      if (type === 'kanBnaBar') {
        // targetColumn.cards.splice(to.toPosition, 0, cards);
        tempBoard.columns[sourceColumnIndex].cards = tempBoard.columns[
          sourceColumnIndex
        ].cards.filter((cd) => !find(cards, { id: cd.id }));
        tempBoard.columns[targetColumnIndex].cards = [...targetColumn.cards, ...cards];
      } else {
        tempBoard.columns[sourceColumnIndex].cards = sourceColumn;
        targetColumn.cards.splice(to.toPosition, 0, cards);
        tempBoard.columns[targetColumnIndex] = targetColumn;
      }
      tempBoard.columns.map((col) => (col['candidateCount'] = col.cards?.length));
    }
    let tempProject = {};
    tempProject.id = tempBoard.id;
    tempProject.projects_pipeline_stages_users = [];

    tempBoard.columns.map((col) => {
      tempProject.projects_pipeline_stages_users = [
        ...tempProject.projects_pipeline_stages_users,
        ...col.cards,
      ];
    });
    tempProject.projects_pipeline_stages_users = tempProject.projects_pipeline_stages_users.map(({ id, project_id, user_id, pipeline_stage_id }) => ({
      id,
      project_id,
      user_id,
      pipeline_stage_id,
    }));
    //
    projectCandidates.forEach((candidate) => {
      if (!candidate.binned) {
        tempProject.projects_pipeline_stages_users.push({
          id: candidate.id,
          project_id: candidate.project_id,
          user_id: candidate.user_id,
          pipeline_stage_id: candidate.pipeline_stage_id,
        });
      }
    });

    // if candidate in projectCandidates is not in projects_pipeline_stages_users, add it
    projectCandidates.forEach((candidate) => {
      if (!find(tempProject.projects_pipeline_stages_users, { id: candidate.id })) {
        tempProject.projects_pipeline_stages_users.push({
          id: candidate.id,
          project_id: candidate.project_id,
          user_id: candidate.user_id,
          pipeline_stage_id: candidate.pipeline_stage_id,
        });
      }
    });
    updateProject(tempProject);
  };

  const getActionBar = useCallback(
    (currentPosition) => (
      <KanbanActionsBar
        pipelineStages={board}
        currentPosition={currentPosition}
        handleKanbanMovement={onCardDragEnd}
        handleUserDelete={handleUserDelete}
        showRestore={true}
      />
    ),
    [board, onCardDragEnd],
  );

  const handleUserDelete = (from) => {
    let tempBoard = cloneDeep(board);
    let tempSelectedCards = [...selectedCard];
    tempSelectedCards = tempSelectedCards.filter(
      (cd) => cd.pipeline_stage_id !== tempBoard.columns[from.fromPosition].id,
    );

    // let filteredCards = tempBoard.columns[from.fromPosition].cards.filter(
    //   (cd) => cd.pipeline_stage_id === tempBoard.columns[from.fromPosition].id,
    // );

    // targetColumn.cards.splice(to.toPosition, 0, cards);
    tempBoard.columns[from.fromPosition].cards = tempBoard.columns[from.fromPosition].cards.filter(
      (cd) => !find(selectedCard, { id: cd.id }),
    );

    let tempProject = {};
    tempProject.id = tempBoard.id;
    tempProject.projects_pipeline_stages_users = [];

    tempBoard.columns.map((col) => {
      tempProject.projects_pipeline_stages_users = [
        ...tempProject.projects_pipeline_stages_users,
        ...col.cards,
      ];
    });

    updateProject(tempProject);
    setSelectedcard(tempSelectedCards);
    setBoard(tempBoard);
  };

  const handleSelectedCards = (card) => {
    let tempSelectedCard = [...selectedCard];

    let index = tempSelectedCard.findIndex((kanabnCard) => kanabnCard.id === card?.id);

    if (index >= 0) {
      tempSelectedCard.splice(index, 1);
    } else {
      tempSelectedCard.push(card);
    }

    setSelectedcard(tempSelectedCard);
  };

  const getCardCheckBox = (card) => {
    return (
      <div className="d-flex align-items-center">
        <label className={classNames('form-element')}>
          <input type="checkbox" onChange={(e) => handleSelectedCards(card)} />
        </label>
      </div>
    );
  };

  return (
    <div
      className={classNames(styles.reactKanbanBoardWrapper, 'pt-2', {
        [styles.sidebarAdjust]: !isEmpty(currentUIState),
        '': !currentUIState,
      })}
    >
      <Page bin>
        {/* <div className={styles.pageWrapperBin}> */}
        {board && (
          <Board
            renderCard={(props) => (
              <KanbanCard
                {...props}
                projectData={projectData.data}
                updateProject={updateProject}
              />
            )}
            onCardDragEnd={onCardDragEnd}
            renderColumnHeader={KanbanColumn}
            disableCardDrag={updateProjectData?.isLoading}
            allowColumnDrag={false}
          >
            {board}
          </Board>
        )}
        {isLoadingProject && <Spinner />}
        {/* </div> */}
      </Page>
    </div>
  );
};
