import '@asseinfo/react-kanban/dist/styles.css';
import classNames from 'classnames';
import Board from '@kaung-limabean/react-kanban-with-bin';

import { KanbanCard } from '@components/kanbanCard/kanbanCard';
import { KanbanColumn } from '@components/kanbanColumn/kanbanColumn';
import styles from 'components/kanbanCard/kanban.module.scss';
import { apiUrls } from 'constants/apiurls';
import { useParams } from 'react-router-dom';
import dataService from '@services/dataService';
import { useEffect, useState } from 'react';
import { Page } from '@components/page/page';
import projects from '../../redux/modules/projects';
import { KanbanActionsBar } from '../../components/kanbanActionBar/kanbanActionBar';
import { cloneDeep, find } from 'lodash';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { handleViewsData } from '../../redux/common/commonSlice';
import { Spinner } from 'components/loader/loader';
import { EmptyCard } from 'components/emptyCard/emptyCard';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import _ from 'lodash';

export const ProjectPipelineContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [board, setBoard] = useState();
  const [selectedCard, setSelectedcard] = useState([]);
  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  const {
    data: projectData,
    isLoading: isLoadingProject,
    error: errorProject,
    isSuccess: successProject,
  } = projects.list().useProjectlistQuery(
    { params: { id: params.projectId }, extendedPath: apiUrls.getProject() },
    {
      skip: !params.projectId,
    },
  );

  const [updateProject, updateProjectData] = projects.form().useProjectupdateMutation();

  useEffect(() => {
    if (successProject || selectedCard?.length) {
      let result = dataService.parsedKanbanColumns(
        projectData?.data,
        selectedCard,
        getActionBar,
        getCardCheckBox,
      );

      setBoard(result);
    } else {
      setBoard(null);
    }
  }, [projectData, selectedCard]);

  useEffect(() => {
    if (updateProjectData?.isSuccess) {
      toast.success('Project Pipeline Updated!');
    }
    if (updateProjectData?.error) {
      toast.error('Failed To Update');
    }
  }, [updateProjectData]);

  useEffect(() => {
    if (!projectData?.data?.pipeline_id) {
      setBoard(null);
    }
    return () => {
      dispatch(handleViewsData(null));
    };
  }, []);

  const onCardDragEnd = (card, from, to, type) => {
    let tempSelectedCards = [...selectedCard];
    let projectCandidates = cloneDeep(projectData?.data?.projects_pipeline_stages_users);
  
    if (type === 'bin' || to?.toColumnId === 'bin') {
      // This handles binning logic when dragging towards the bin
      // Filter out the selected cards from the pipeline stage (from column)
      let cardsToBin = tempSelectedCards.filter((cd) => cd.pipeline_stage_id === from.fromColumnId);
      if (to?.toColumnId === 'bin') {
        cardsToBin = [card];
      }

      // Remove those selected cards from the `selectedCard` state
      tempSelectedCards = tempSelectedCards.filter(
        (cd) => cd.pipeline_stage_id !== from.fromColumnId,
      );
  
      // Mark the filtered cards as binned
      projectCandidates.forEach((candidate) => {
        if (find(cardsToBin, { id: candidate.id })) {
          candidate.binned = 1; // Mark candidate as binned
        }
      });

      projectCandidates = projectCandidates.map(({ id, project_id, user_id, pipeline_stage_id, binned }) => ({
        id,
        project_id,
        user_id,
        pipeline_stage_id,
        binned
      }));
  
      // Prepare updated project data to be sent to the backend
      let updatedProject = {
        id: parseInt(params?.projectId),
        projects_pipeline_stages_users: projectCandidates,
      };
  

      // Update the project on the server
      updateProject(updatedProject);
  
      // Update the selectedCard and board states
      setSelectedcard(tempSelectedCards);
      setBoard(dataService.parsedKanbanColumns(projectData?.data, tempSelectedCards, getActionBar, getCardCheckBox));
  
      return; // Stop here after handling bin logic
    }
  
    // Regular Kanban movement logic below
    let tempBoard = cloneDeep(board);
    let sourceColumnIndex = tempBoard.columns.findIndex((col) => col.id === from.fromColumnId);
    let targetColumnIndex = tempBoard.columns.findIndex((col) => col.id === to.toColumnId);
  
    let sourceColumn = tempBoard.columns
      .find((col) => col.id === from.fromColumnId)
      .cards.filter((card, index) => index !== from.fromPosition);
  
    let targetColumn = tempBoard.columns.find((col) => col.id === to.toColumnId);
  
    if (from.fromColumnId === to.toColumnId) {
      // Moving within the same column
      sourceColumn.splice(to.toPosition, 0, card);
      tempBoard.columns[sourceColumnIndex].cards = sourceColumn;
    } else {
      // Moving between different columns
      tempBoard.columns[sourceColumnIndex].cards = sourceColumn;
      targetColumn.cards.splice(to.toPosition, 0, card);
      tempBoard.columns[targetColumnIndex] = targetColumn;
      card.pipeline_stage_id = to.toColumnId;
    }
  
    // Update the board
    setBoard(tempBoard);
    let projects_pipeline_stages_users = tempBoard.columns.flatMap(col => col.cards);
    projects_pipeline_stages_users = projects_pipeline_stages_users.map(({ id, project_id, user_id, pipeline_stage_id }) => ({
      id,
      project_id,
      user_id,
      pipeline_stage_id,
    }));

    // append binned candidate from projectCandidates to projects_pipeline_stages_users
    projectCandidates.forEach((candidate) => {
      if (candidate.binned) {
        projects_pipeline_stages_users.push({
          id: candidate.id,
          project_id: candidate.project_id,
          user_id: candidate.user_id,
          pipeline_stage_id: candidate.pipeline_stage_id,
        });
      }
    });

    // if candidate in projectCandidates is not in projects_pipeline_stages_users, add it
    projectCandidates.forEach((candidate) => {
      if (!find(projects_pipeline_stages_users, { id: candidate.id })) {
        projects_pipeline_stages_users.push({
          id: candidate.id,
          project_id: candidate.project_id,
          user_id: candidate.user_id,
          pipeline_stage_id: candidate.pipeline_stage_id,
        });
      }
    });
    

    updateProject({
      id: tempBoard.id,
      projects_pipeline_stages_users: projects_pipeline_stages_users
    });
  };
  

  const getActionBar = (currentPosition) => {
    return (
      <KanbanActionsBar
        pipelineStages={board}
        currentPosition={currentPosition}
        handleKanbanMovement={onCardDragEnd}
        handleUserDelete={handleUserDelete}
        visible
        showBin={true}
      />
    );
  };
  const handleUserDelete = (from) => {
    let tempBoard = cloneDeep(board);
    let tempSelectedCards = [...selectedCard];
    tempSelectedCards = tempSelectedCards.filter(
      (cd) => cd.pipeline_stage_id !== tempBoard.columns[from.fromPosition].id,
    );
    tempBoard.columns[from.fromPosition].cards = tempBoard.columns[from.fromPosition].cards.filter(
      (cd) => !find(selectedCard, { id: cd.id }),
    );

    let tempProject = {};
    tempProject.id = tempBoard.id;
    tempProject.projects_pipeline_stages_users = [];

    tempBoard.columns.map((col) => {
      tempProject.projects_pipeline_stages_users = [
        ...tempProject.projects_pipeline_stages_users,
        ...col.cards,
      ];
    });

    updateProject(tempProject);
    setSelectedcard(tempSelectedCards);
    setBoard(tempBoard);
  };

  const handleSelectedCards = (e, card) => {
    // e.stopPropogation();
    let tempSelectedCard = [...selectedCard];

    let index = tempSelectedCard.findIndex((kanabnCard) => kanabnCard.id === card?.id);

    if (index >= 0) {
      tempSelectedCard.splice(index, 1);
    } else {
      tempSelectedCard.push(card);
    }

    setSelectedcard(tempSelectedCard);
    // setTick(card);
  };

  const getCardCheckBox = (card) => {
    return (
      <div className="d-flex align-items-center">
        <label className={classNames('form-element')}>
          <input type="checkbox" onChange={(e) => handleSelectedCards(e, card)} />
        </label>
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(styles.reactKanbanBoardWrapper, 'mt-5 pt-5', {
          [styles.sidebarAdjust]: !isEmpty(currentUIState),
          '': !currentUIState,
        })}
        style={!isEmpty(currentUIState) ? { marginLeft: '320px' } : { marginLeft: '0' }}
      >
        <Page>
          {board && (
            <Board
              renderCard={(props) => (
                <KanbanCard
                  {...props}
                  projectData={projectData.data}
                  updateProject={updateProject}
                />
              )}
              onCardDragEnd={onCardDragEnd}
              allowRemoveCard={false}
              renderColumnHeader={KanbanColumn}
              disableCardDrag={updateProjectData?.isLoading}
              disableColumnDrag={true}
            >
              {board}
            </Board>
          )}
          {!board && !isLoadingProject && (
            <EmptyCard
              isCard
              title="Things are looking a little\nbare here."
              description="You haven’t added any pipeline to this project yet."
            ></EmptyCard>
          )}
        </Page>

      </div>
    </>
  );
};
